@import (less) "mixins.less";

/*************FORMS************/
@content: #666;
@labelColor: #666;
@inputColor: #bfbfbf;
@inputBG: #fff;
@inputBorder: @content;
@inputFocusBorder: #666;
@inputFocusBG: #fff;
@errorBG: #bfbfbf;
@errorBorder: #f10e0e;
@errorLabel: #f10e0e;

.gform_wrapper{
            .bodyfont()!important;
            width:100%!important;
            margin-top:0!important;
            label{
                .pathway(24px)!important;
                .uppercase();
                display:block;
                color:@content;
                }
            .ginput_complex label{
                font-size:12px!important;
                }
            input[type=text], textarea{
            	.bodyfont();
                background:@inputBG;
                border:1px solid @grayLight;
                background:rgba(0,0,0,0.02);
                padding:12px!important;
                width:100%!important;
                color:@content;
                font-size:16px;
                outline: none;
                .transition();
                ::-webkit-input-placeholder {
                       color: #fff;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                       color: #fff;  
                    }
                    
                    ::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff;  
                    }
                    
                    :-ms-input-placeholder {  
                       color: #fff;  
                    }
                }
            input[type=text]:focus, textarea:focus{
                background:rgba(0,0,0,0.04);
                }
            
            select{
                width:100%!important;
                }
                
              
            ul.gform_fields{
                padding:0px 0 10px 0!important;
                margin:0!important;
                li{
                    padding-left:0!important;
                    padding-bottom:3px!important;
                    background:none!important;
                    max-width:100%!important;
                    margin-bottom:0!important;
                    span.icon{display:none!important;}
                    &.gfield.gf_left_half{
                    	width:49%!important;
                    	margin-right:1%!important;
                    	}
                    &.gfield.gf_right_half{
                    	width:49%!important;
                    	margin-left:1%!important;
                    	}
            		.gfield_description{
            			.italic();
            			color:@content;
            			}
            		span.name_first, span.name_last{
            			}
                    }
                li.gfield_error{
                    border:none;
                    background:transparent!important;
                    padding:0 10px 0 10px!important;
                    max-width: 100%!important;
                    width:100%!important;
                    .ginput_container{margin-top:8px!important;}
                    &:not(.gf_left_half):not(.gf_right_half){max-width:100%!important;}
                    label, .gfield_description{
                        color:@yellow!important;
                        margin-top:0!important;
                        }
                    input[type=text], textarea{border:1px solid @yellow!important;}
                    }
                }
            }
.validation_error{
    color:@yellow!important;
    .pathway(20px)!important;
    .uppercase();
    border-top:1px solid @blue!important;
    border-bottom: 1px solid @blue!important;
    }
.gform_footer{
    margin-top:0!important;
    padding-top:0!important;
    text-align:left;
    button span.text{font-size: 20px!important;}
    }
.gform_confirmation_message{
    .pathway(22px);
    padding:20px;
    text-align:center;
    color:@blue;
    }
    
.gform_wrapper.two-column_wrapper {
	max-width: 100%;
}

.gform_wrapper.two-column_wrapper ul.gform_fields,
.gform_wrapper.two-column_wrapper ul.gform_column li.gsection:first-child {
	display: none;
}

.gform_wrapper.two-column_wrapper ul.gform_fields.gform_column {
	display: block;
	float:left;
}

.gform_wrapper.two-column_wrapper ul.one-half {
	margin-left: 6% !important;
	width: 47%;
}
.gform_wrapper.two-column_wrapper ul.one-half.first {
	margin-left: 0 !important;
}


.gform_wrapper .gsection {
	border-bottom: none !important;
}

.gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
	padding: 0 !important;
}

@media only screen and (max-width: 1075px) {
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		width: 100%;
	}
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		margin-left: 0 !important;
	}
	
	.gform_wrapper .gsection {
		display: none !important; /* remove !important if it's not needed */
	}
	
	#gform_wrapper_1 li {
		margin-bottom: 10px;
	}
	
}

img.gform_ajax_spinner {
    display: none !important;
}

@media only screen and (min-width: 641px){
	#main-content .gform_wrapper{
		&.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
			max-width:100%!important;
			}
	}
}